import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/HomeHero";
import HomeTestimonials from "root/sections/Testimonials";
import HomeValuePropositions from "root/sections/HomeValuePropositions";
import ProjectsSection from "root/sections/ProjectsSection";
import HomeInstitutions from "root/sections/HomeInstitutions";
import withTrans from "root/i18n/withTrans";
import Footer from "root/components/Footer";

/* eslint-disable id-length */
const IndexPage = ({ t }) => {
  const data = useStaticQuery(graphql`
    query {
      testimonial: file(relativePath: { eq: "testimonial.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
    </div>
    // <div>
    //   <SEO title="Sioslife" description="" keywords="" />
    //   <Layout>
    //     <Navbar currentPage="home" translations={t("pages")} />
    //     <Hero translations={t("home.hero")} />
    //     <HomeValuePropositions translations={t("home.values")} />
    //     <ProjectsSection translations={t("home.projects")} />
    //     <HomeTestimonials
    //       image={data}
    //       color="blue"
    //       videoUrl="https://www.youtube.com/embed/fLB4i9SpJN8"
    //       translations={t("home.testimonials")}
    //     />
    //     <HomeInstitutions translations={t("home.institutions")} />
    //     <Footer translations={t("pages")} />
    //   </Layout>
    // </div>
  );
};

IndexPage.propTypes = {
  t: PropTypes.func.isRequired,
};
/* eslint-enable id-length */

export default withTrans(IndexPage);
